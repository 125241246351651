import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Formik } from "formik";
import * as Yup from "yup";
import { Col } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import queryString from 'query-string';

Yup.setLocale({
  string: {
    email: 'Must be a valid email address',
    min: ({ min }) => `Must be at least ${min} characters`
  }
});

const validationSchema = Yup.object({
  email: Yup.string().email().required("Required!"),
  firstName: Yup.string().min(2).required("Required!"),
  surname: Yup.string().min(2).required("Required!"),
  tel: Yup.number().min(6).required("Required!"),
  query: Yup.string().required("Required!"),
  subject: Yup.string().required("Required!"),
  postcode: Yup.string().min(3).required("Required!")
});

// const subjects = {
//   'manual-lessons': 'Manual Lessons',
//   'automatic-lessons': 'Automatic Lessons',
//   'female-instructors': 'Female Instructors',
//   'motorway-lessons': 'Motorway Lessons',
//   'pass-plus': 'Pass Plus',
//   'refresher-lessons': 'Refresher Lessons',
//   'practical-exam': 'Practical Exam'
// }

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submitted: '' };
    this.getEmailSubject = this.getEmailSubject.bind(this);

    this.initalValues = {
      firstName: '',
      surname: '',
      tel: '',
      email: '',
      subject: this.getEmailSubject(),
      query: '',
      postcode: ''
    }

    this.sendEmail = this.sendEmail.bind(this);
    this.renderPostSubmitMsg = this.renderPostSubmitMsg.bind(this);
  }

  getEmailSubject() {
    let subject = queryString.parse(this.props.subject).subject;

    if (subject) {
      subject = subject.replace(/-/g, ' ');
      subject = subject.charAt(0).toUpperCase() + subject.slice(1);
    }

    return subject || ''
  }

  sendEmail(values) {
    emailjs.send('infoSwift', 'contactform', values, 'user_9htZOLFADee4rHxtUIoU0')
      .then((result) => {
        this.setState({ submitted: 'success' });
      }, (error) => {
        this.setState({ submitted: 'error' });
      });
  }

  renderPostSubmitMsg() {
    if (this.state.submitted === 'success') {
      return (
        <div className="padding-top--base margin-top--double text-center success">
          Successfully submitted!
        </div>
      )
    } else if (this.state.submitted === 'error'){
      return (
        <div className="padding-top--base margin-top--double text-center red">
          Failed!
        </div>
      )
    } else {
      return ''
    }
  }

  render() {
    return(
      <Fragment>
        <div className="box-shadow center-content contact_us-form_wrapper">
          <Formik
            initialValues={this.initalValues}
            onSubmit={(values) => { this.sendEmail(values); } }
            validationSchema={validationSchema}
          >
            {({
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                <Form.Row className="padding-bottom--base">
                  <Form.Group as={Col} md={4} controlId="validateFirstName">
                    <Form.Label><span className="red">*</span>First Name:</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.firstName && touched.firstName}
                      placeholder='First Name'
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={4} controlId="validateSurname">
                    <Form.Label><span className="red">*</span>Surname:</Form.Label>
                    <Form.Control
                      type="text"
                      name="surname"
                      value={values.surname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.surname && touched.surname}
                      placeholder='Surname'
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.surname}
                    </Form.Control.Feedback>
                  </Form.Group>
                    <Form.Group as={Col} md={4} controlId="validateTelephone">
                      <Form.Label><span className="red">*</span>Telephone No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="tel"
                        value={values.tel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.tel && touched.tel}
                        placeholder='Telephone number'
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.tel}
                      </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="validationEmail">
                    <Form.Row>
                      <Form.Label column="md" md={2}>
                        <span className="red">*</span>Email:
                    </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder="Enter your email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={errors.email && touched.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Row>
                      <Form.Label column="md" md={2}><span className="red">*</span>Subject:</Form.Label>
                      <Col>
                        <Form.Control
                        type="text"
                        name="subject"
                        value={values.subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.subject && touched.subject}
                        placeholder='"Manual lessons", "Automatic lessons", etc.'
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.subject}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Row>
                      <Form.Label column="md" md={2}><span className="red">*</span>Postcode:</Form.Label>
                      <Col>
                        <Form.Control
                        type="text"
                        name="postcode"
                        value={values.postcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.postcode && touched.postcode}
                        placeholder='Please provide your postcode.'
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.postcode}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <Form.Label><span className="red">*</span>Query:</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    rows="10"
                    name="query"
                    value={values.query}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.query && touched.query}
                  />
                <Form.Control.Feedback type="invalid">
                    {errors.query}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>

        {this.renderPostSubmitMsg()}

        <p className="padding-top--base margin-top--double text-center">
          <strong>Please note!</strong><br/>
          The information collected from this form is required for the sole purpose of contacting you in regard to your query.<br/>
          Your information will <strong>NOT</strong> be used for marketing purposes or shared with third-party individuals or organizations.
        </p>
      </Fragment>
    )
  }
}
