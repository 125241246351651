import React, { Fragment } from 'react';
import { Navbar, Image, Button } from 'react-bootstrap';
import swiftLearner from '../assets/content/images/swift-learner-driving-school.png';
import fLogo from '../assets/content/images/icons/f_logo.png';
import tLogo from '../assets/content/images/icons/t_logo2.svg';
import iLogo from '../assets/content/images/icons/i_logo.png';
import ytLogo from '../assets/content/images/icons/yt_icon.svg';

const Header = (() => {
  let linkFacebook = 'https://www.facebook.com/swiftlearner';
  let linkInstagram = 'https://www.instagram.com/swiftlearner';
  let linkTwitter = 'https://twitter.com/learner_swift';
  let linkYoutube = 'https://www.youtube.com/channel/UC0IcpviDApSfqFGoKrQ1ZTg';

  return(
    <Fragment>
      <div className="header-div-wrapper content--center">
        <Navbar.Brand href="/"><Image className="navbar-logo" src={swiftLearner} alt='Swift Learner Logo'/></Navbar.Brand>
      </div>
      <div className="text-right header-content-div-wrapper">
        <div className="header-content-div">
          <div className="header-content-btn-div">
            <Button href="/contact" primary="success" className="get-quote_btn get-quote_btn--small margin-right--base" alt="Get a quote">BOOK A LESSON</Button>
            <div className="margin-right--base hidden--xs">
              <a href={linkFacebook} target="_blank" rel="noopener noreferrer" title="Swift Learner Facebook page">
                <div className="header-content-social_wrapper">
                  <Image src={fLogo} fluid rounded alt="Swift Learner Facebook page"/>
                </div>
              </a>
              <a href={linkInstagram} target="_blank" rel="noopener noreferrer" title="Swift Learner Instagram page">
                <div className="header-content-social_wrapper">
                  <Image src={iLogo} fluid rounded alt="Swift Learner Instagram page"/>
                </div>
              </a>
              <a href={linkTwitter} target="_blank" rel="noopener noreferrer" title="Swift Learner Twitter page">
                <div className="header-content-social_wrapper header-content-social_wrapper_grey_fill">
                  <Image src={tLogo} fluid rounded alt="Swift Learner Twitter page"/>
                </div>
              </a>
              <a href={linkYoutube} target="_blank" rel="noopener noreferrer" title="Swift Learner YouTube channel">
                <div className="header-content-social_wrapper header-content-social_wrapper_grey_fill">
                  <Image src={ytLogo} fluid alt="Swift Learner YouTube channel"/>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
})

export default Header;
