import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';

import fLogo from '../assets/content/images/icons/f_logo2.png';
import tLogo from '../assets/content/images/icons/t_logo4.svg';
import iLogo from '../assets/content/images/icons/i_logo2.png';
import ytLogo from '../assets/content/images/icons/yt_icon.svg';

export default class Footer extends React.Component {
  renderInternallink(link, text,) {
    let linkTitle = `Swift Learner ${text}`

    return(
      <ListGroup.Item action href={link} className="footer-social-link" title={linkTitle}>
        {text}
      </ListGroup.Item>
    )
  }

  renderExternallink(link, text) {
    return (
      <ListGroup.Item action href={link} target="_blank" rel="noopener noreferrer" className="footer-social-link" title={text}>
        {text}
      </ListGroup.Item>
    )
  }

  // iconFor(arg) {
  //   stack = {
  //     'Facebook': fLogo,
  //     ''
  //   }
  // }

  render() {
    let dvlaUrl = 'https://www.gov.uk/government/organisations/driver-and-vehicle-licensing-agency';
    let roadSafetyUrl = 'https://www.gov.uk/browse/driving/highway-code-road-safety';
    let linkFacebook = 'https://www.facebook.com/swiftlearner';
    let linkInstagram = 'https://www.instagram.com/swiftlearner';
    let linkTwitter = 'https://twitter.com/learner_swift';
    let linkYoutube = 'https://www.youtube.com/channel/UC0IcpviDApSfqFGoKrQ1ZTg';
    let preparationLink = '/theory-test';
    let passPlusLink = '/pass-plus';

    return (
      <Fragment>
        <Row className="page-width-container text-md-left">
          <Col sm md={{ span: 3, offset: 1 }} className="margin-top--base">
            <h5 className="footer-headings padding-bottom--half">Quick links</h5>
            <ListGroup as="ul" variant='flush' className="footer-social-link--pad-side">
              {this.renderInternallink('/how-to-start-driving', 'How to Start Driving')}
              {this.renderInternallink('/become-an-instructor', 'Become an Instructor')}
              {this.renderInternallink('/join-our-franchise', 'Join our Franchise')}
              {this.renderInternallink('/faq', 'FAQs')}
            </ListGroup>
          </Col>
          <Col sm md={3} className="margin-top--base">
            <h5 className="footer-headings padding-bottom--half">Other links</h5>
            <ListGroup as="ul" variant='flush' >
              {this.renderExternallink(dvlaUrl, 'DVLA')}
              {this.renderExternallink(roadSafetyUrl, 'Road safety')}
              {this.renderInternallink(preparationLink, 'Preparation')}
              {this.renderInternallink(passPlusLink, 'After you pass your test')}
            </ListGroup>
          </Col>
          <Col sm={5} md={4} lg={3} xl={{ span: 3, offset: 1}}>
            <div className="padding-left--footer-col margin-top--base">
              <h5 className="footer-headings padding-bottom--half no-padding--left">Get in touch</h5>
              <div>
                <a className="footer-contact footer-contact_phone" href="tel:07796 97 97 99">07796 97 97 99</a>
              </div>
              <div>
                <a className="footer-contact footer-contact_email" href="mailto:info@swiftlearner.co.uk">info@swiftlearner.co.uk</a>
              </div>
              <div className="margin-right--base footer-social_media_wrapper">
                <a href={linkFacebook} target="_blank" rel="noopener noreferrer" title="Swift Learner Facebook page">
                  <div className="header-content-social_wrapper">
                    <Image src={fLogo} fluid rounded alt="Swift Learner Facebook page"/>
                  </div>
                </a>
                <a href={linkInstagram} target="_blank" rel="noopener noreferrer" title="Swift Learner Instagram page">
                  <div className="header-content-social_wrapper">
                    <Image src={iLogo} fluid rounded alt="Swift Learner Instagram page"/>
                  </div>
                </a>
                <a href={linkTwitter} target="_blank" rel="noopener noreferrer" title="Swift Learner Twitter page">
                  <div className="header-content-social_wrapper">
                    <Image src={tLogo} fluid rounded alt="Swift Learner Twitter page"/>
                  </div>
                </a>
                <a href={linkYoutube} target="_blank" rel="noopener noreferrer" title="Swift Learner YouTube channel">
                  <div className="header-content-social_wrapper">
                    <Image src={ytLogo} fluid alt="Swift Learner YouTube channel"/>
                  </div>
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <div className="footer-tnc">
          <p>© Swift Learner 2020 | <b><a href="/terms" title="Terms and Conditions" className="footer-social-link footer-social-link-terms">Our Terms & Conditions</a></b></p>
        </div>
      </Fragment>
    )
  }
}
