import React from 'react';
import posed from 'react-pose';
import { Row, Col, Image, Button } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

import placeholderImage from '../../assets/content/images/swift_learner_car-compressed.jpg';

// Pose sidebar items
const List = posed.ul({
  open: {
    x: '0%',
    delayDchildren: 400,
    staggerChildren: 300
  },
  closed: { x: '-110%', delay: 300 }
})

const Children = posed.li({
  hoverable: true,
  open: { y: 0, opacity: 1, },
  closed: { y: 20, opacity: 0 },
  init: { scale: 1 }
})

const highlightList = [
  {
    text: '5 Star Rated Driving School',
    style: ''
  },
  {
    text: 'Excellent Pass Rate of Over 90%',
    style: ''
  },
  {
    text: 'Over 10 Years of Experience',
    style: ''
  },
  {
    text: 'Free access to Theory Test Pro',
    style: ''
  }
]

export default class HomeHeroAsset extends React.Component {
  state = { isOpen: false }

  toggleNav = () => this.setState({ isOpen: !this.state.isOpen });

  componentDidMount() {
    setTimeout(this.toggleNav, 1000)
  }

  renderHighlights() {
    return (
      highlightList.map(h =>
        <Children key={h.text} className={h.style}>{h.text}</Children>
      )
    )
  }

  render() {
    const { isOpen } = this.state;

    return (
      <div className="page-width-container hero-asset home-heading margin-bottom--base padding-bottom--double no-margin--top_small">
        <Row xs={1} md={2} className="padding-top--base padding-bottom--base">
          <Col lg md={12} xs sm={12} className="home-highlight_list-col">
            <div className="screen-width-container padding-top--base padding-bottom--half home-heading home-heading--home no-padding--top_small">
              <h1>Learn to drive with the highest rated driving school in Coventry!</h1>
            </div>
            <div className="home-highlight_list-wrapper padding-top--base no-padding--left no-padding--right padding-bottom--base">
              <List className="list-unstyled home-highlight_list text-center" pose={isOpen ? 'open' : 'closed'}>
                {this.renderHighlights()}
              </List>
            </div>
            <div className="get-quote-wrapper justify-content-center">
              <div className="padding-top--double padding-bottom--half get-quote_btn-wrapper">
                <div className='margin-bottom--half'>
                  <Button href="/contact" primary="success" className="get-quote_btn" alt="Get a quote">BOOK A LESSON</Button>
                </div>
                <div>
                  <div className="margin-top--half">
                    <p>
                      <a className="tel-link tel-link-font-size" href="tel:07796 97 97 99">
                        07796 97 97 99
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg md={12} xs={16} sm={12} className='center-items'>
            <div className="margin-top--double">
              <LazyLoad height={400}>
                <Image src={placeholderImage} alt="Swift Learner Car" rounded fluid/>
              </LazyLoad>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
