import React, { Fragment } from 'react';

import Button from 'react-bootstrap/Button';
import '../../assets/styles/pages.scss';

export default class BottomPartial extends React.Component {

  render() {
    let { button_txt, url } = this.props;

    return(
      <Fragment>
        <div className="home-heading margin-bottom--double padding-top--double padding-bottom--half highlighted-bottom-partial text-center">
          <h3>Swift Learner is here to help you every step of the way.</h3>
          <Button href={url} primary="success" className="section-btn margin-top--base margin-bottom--base section-col_course_link--btn" alt='Book now'>{button_txt}</Button>
        </div>
      </Fragment>
    )
  }
}
