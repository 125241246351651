import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import { Image } from 'react-bootstrap';
import { Helmet } from "react-helmet";

import '../../assets/styles/pages.scss';

import BottomPartial from './bottomPartial';
import signs from '../../assets/content/images/signs-small.jpg';

const TheoryTest = (() => {
  let page_title = "Theory test";
  let desc = "Here at Swift Learner we have partnered up with Theory Test Pro to provide you with free online training app to help you pass your theory test. Start learning now!"
  let canonicalUrl = 'https://www.swiftlearner.co.uk/theory-test';

  return (
    <Fragment>
      <Helmet>
        <title>{page_title} | Swift Learner Driving School</title>
        <meta name="description" content={desc} />
        <link rel="canonical" href={canonicalUrl}/>
      </Helmet>
      <div className="page-width-container margin-bottom--double margin-top--double">
        <div className="highlighted">
          <div className="home-heading highlighted-title">
            <h1 className="home-heading--large text-left">{page_title}</h1>
          </div>
          <div className="wave_svg_wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className="wave_svg" viewBox="0 0 500 110" preserveAspectRatio="xMinYMax meet">
              <g className="wave">
                {/* <path d="M0.00,49.98 C69.98,95.22 430.58,2.47 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" /> */}
                <path d="M0.00,49.98 C185.66,45.88 264.67,90.88 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" />
              </g>
            </svg>
          </div>
          <div className="highlighted-image-wrapper">
            <LazyLoad height={350}>
              <Image src={signs} fluid rounded />
            </LazyLoad>
          </div>
        </div>
      </div>
      <div className="page-width-container pages-content-container padding-top--base padding-bottom--double">
        <div className="home-heading highlighted-title_content">
          <h1>{page_title}</h1>
        </div>
        <div className="narrow_content home-heading margin-bottom--base padding-top--base padding-bottom--half">
          <p>
            Here at Swift Learner we have partnered up with Theory Test Pro to provide you with free online training app to help you pass your theory test. Your instructor will register you and provide with access to the online training app once you have started driving lessons and are ready to start learning for your theory test.
          </p>
          <p>
            You will need to pass a theory test before you can take your practical driving test. The theory test is made up of two sections, a multiple-choice knowledge section followed by a hazard perception section. To pass you will need to correctly answer 43 of the 50 multiple choice questions and score a minimum of 44 out of the available 75 points in the Hazard perception test. You will receive your results and a certificate shortly after completing the test.
          </p>
          <p>
            You can book a test on the official website <a href="https://www.gov.uk/book-driving-test" className='red'>here</a> or by calling 0300 200 1122 Monday to Friday 8am-4pm. The theory test costs £23 and includes both the multiple choice and hazard perception sections.
          </p>
          <p>
            The video below explains in details what the test consist of and the nature of the questions.
          </p>
          <LazyLoad>
            <div className='iframe-wrapper padding-top--double padding-bottom--double margin-bottom--double border_line--bottom'>
              <iframe loading="lazy" width="720" height="410" src="https://www.youtube-nocookie.com/embed/6BxQr4k8n8M?controls=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="Theory test 2020: official DVSA guide" allowFullScreen></iframe>
            </div>
          </LazyLoad>
          <LazyLoad>
            <BottomPartial button_txt='Contact Us' url='/contact' />
          </LazyLoad>
        </div>
      </div>
    </Fragment>
  )
})

export default TheoryTest;
