import React from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel'
import Col from 'react-bootstrap/Col';
import LazyLoad from 'react-lazyload';

import gear from '../../assets/content/images/gear-small.jpg';
import auto from '../../assets/content/images/automatic-small.jpg';
import motorway from '../../assets/content/images/highway-small.jpg'

export default class Courses extends React.Component {
  renderCol(title, details, url, image, unavailable) {
    let {price, duration, desc } = details;

    return (
      <Col xl={3} lg={3} md={4} sm={6} xs={8} className="section-col section-col_course no-padding--left no-padding--right alight-self-center margin-top--base margin-bottom--base">
          <div className="section-col_course_image_wrapper">
              <Image src={image} alt={title} className="padding-bottom--base section-col_course_image-round--top" />
          </div>
        <div className="narrow_content section-col_course-div">
          <div className="section-col_course-title-wrapper">
            <h5 className="section-col_course-title">{title}</h5>
          </div>
          <div className="section-col_course_desc">
            <dl>
              <dt>Price:</dt><dd>£{price}</dd>
              <dt>Duration:</dt><dd>{duration}</dd>
            </dl>
            <div className="section-col_course_desc-info">
              <p dangerouslySetInnerHTML={{__html: desc}}></p>
            </div>
          </div>
          <Button href={url} primary="success" className="section-btn margin-top--base margin-bottom--base section-col_course_link--btn" alt='Book now' disabled={unavailable}>Book Lesson</Button>
          {this.renderUnavailableMessage(unavailable)}
        </div>
      </Col>
    )
  }

  renderUnavailableMessage(unavailable) {
    if (unavailable) {
      return (
        <div>
          <p className='unavailable'>This course will be available soon!</p>
        </div>
      )
    }
    return ''
  }

  render() {
    return (
      <div className="screen-width-container padding-top--max padding-bottom--tripple margin-top--double home-heading" id="courses">
        <div className="text-center home-heading home-heading--home home-heading--grey">
          <h2>Courses</h2>
        </div>
        <LazyLoad height={600}>
          <Carousel
            interval={13000}
            nextIcon={<span aria-hidden="true" className="carousel-control-next-icon carousel-control-next-icon--blue" />}
            prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon carousel-control-prev-icon--blue" />}
            >
            <Carousel.Item>
              <div className="page-width-container no-padding--right no-padding--left">
                <div className="row section justify-content-center text-center">
                  {this.renderCol(
                    'Manual Lesson',
                    {
                      price: '65.00',
                      duration: '2 hours',
                      desc: 'One-to-One lesson in a manual car, specifically tailored for your needs and requirements.'
                    },
                    '/contact?subject=manual-lessons',
                    gear)}

                  {this.renderCol(
                    'Automatic Lesson',
                    {
                      price: '70.00',
                      duration: '2 hours',
                      desc: 'One-to-One lesson in an automatic car, specifically tailored for your needs and requirements.'
                    },
                    '/contact?subject=automatic-lessons',
                    auto)}
                  
                  {this.renderCol(
                    'Test Day Booking in Coventry',
                    {
                      price: '80.00',
                      duration: '2 hours',
                      desc: 'Prior assessment is required, subject to <a href="/terms" title="Terms and Conditions" class="red section-inner_link">Terms & Conditions</a>'
                    },
                    '/contact?subject=test-day-booking-in-cov',
                    gear)}
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="page-width-container no-padding--right no-padding--left">
                <div className="row section justify-content-center text-center">
                  {this.renderCol(
                    'Test Day Booking Outside Coventry',
                    {
                      price: '90.00',
                      duration: '2 hours',
                      desc: 'Prior assessment is required, subject to <a href="/terms" title="Terms and Conditions" class="red section-inner_link">Terms & Conditions</a>'
                    },
                    '/contact?subject=test-day-booking-outside-cov',
                    gear)}

                  {this.renderCol(
                    'Female Instructor Manual Lesson',
                    {
                      price: '65',
                      duration: '2 hours',
                      desc: 'One-to-One lesson with a female instructor in a manual car, specifically tailored for your needs and requirements.'
                    },
                    '/contact?subject=female-manual',
                    gear)}

                  {this.renderCol(
                    'Female Instructor Automatic Lessons',
                    {
                      price: '70',
                      duration: '2 hours',
                      desc: 'One-to-One lesson with a female instructor in an automatic car, specifically tailored for your needs and requirements.'
                    },
                    '/contact?subject=female-automatic',
                    auto)}
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="page-width-container no-padding--right no-padding--left">
                <div className="row section justify-content-center text-center">
                  {this.renderCol(
                    'Refresher manual lesson',
                    {
                      price: '70.00',
                      duration: '2 hours',
                      desc: 'Each lesson will be tailored to suit your needs and with no test you can have as many or as few as it takes to feel more confident on the road.'
                    },
                    '/contact?subject=refresher-manual',
                    gear)}

                  {this.renderCol(
                    'Pass Plus course',
                    {
                      price: '220',
                      duration: '6 hours',
                      desc: 'Pass Plus consists of 6 modules, each of them designed to give the driver experience driving.'
                    },
                    '/contact?subject=pass-plus',
                    gear)}

                  {this.renderCol(
                    'Motorway lesson',
                    {
                      price: '70',
                      duration: '2 hours',
                      desc: 'A motorway lesson will cover all you need to know whilst safely guiding you through your first experience of motorway driving.'
                    },
                    '/contact?subject=motorway',
                    motorway)}
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </LazyLoad>
      </div>
    )
  }
}
