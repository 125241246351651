import React, { Fragment } from 'react';
import {Row, Col, Image} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
import LazyLoad from 'react-lazyload';

import yellIcon from '../../assets/content/images/yell_icon.png';
import freeIndexIcon from '../../assets/content/images/freeindex_icon.png';
import googleIcon from '../../assets/content/images/google_icon.png';

const images = {
  'Yell': yellIcon,
  'FreeIndex': freeIndexIcon,
  'Google': googleIcon
}

export default class Reviews extends React.Component {
  renderReview(title, text, date, source, colNum = 3) {
    let classes = `padding-top--double reviews-wrapper`;

    return (
      <Fragment>
        <Col xs={8} md={6} lg={colNum} xl={4} className={classes}>
          <Row className="home-heading">
            <div className="col col-md col-lg star-ratings-css star-ratings-css--small" title="Yell 5 star"></div>
            <Col md lg className="reviews-reviewer">
              <h5>{title}</h5>
            </Col>
            <Col md lg>
              <p className="reviews-review_date">
                <i>{date}</i>
                {/* <br /> */}
                &nbsp;on <strong>{source}</strong>
              </p>
            </Col>
          </Row>
          <p className="reviews-review_text"><strong>"</strong>{text}<strong>"</strong></p>
          <hr className="short"/>
        </Col>
      </Fragment>
    )
  }

  renderPlatform(platform, rating, reviewsCount, link) {
    let title = platform.match(/([^\W])+/);
    let linkTitle = `Swift Learner - ${title[0]} page`;
    let imageTitle = `${title[0]} logo`;
    let imageSrc = images[title[0]];

    return(
      <Col sm={4} className="margin-bottom--double_lg">
        <a href={link} target="_blank" rel="noopener noreferrer" className="reviews-summary__link" title={linkTitle}>
          <LazyLoad height={100}>
            <div className="reviews-logo-wrapper">
              <Image src={imageSrc} alt={imageTitle} rounded fluid />
            </div>
          </LazyLoad>
          <div className="reviews-platform-wrapper">
            <p className="reviews-platform">
              <strong>{platform}</strong>
            </p>
            <p className="star-ratings-css padding-bottom--base" />
          </div>
            <p>Rated <strong>{rating}</strong> based on over {reviewsCount} reviews</p>
        </a>
      </Col>
    )
  }

  render() {
    let yellSwiftLearner = 'https://www.yell.com/biz/swift-learner-coventry-8094302/'
    let freeIndexSwiftLearner = 'https://www.freeindex.co.uk/profile(swift-learner)_629328'
    let googleSwiftLearner = 'https://www.google.co.uk/search?source=hp&ei=GResXrTfOJCnUs-5kYgF&q=swift+learner&oq=swift+learner&gs_lcp=CgZwc3ktYWIQAzICCAAyAggAMgIIADICCAAyAggAMgIIADICCAAyAggAMgIIADICCAA6BQgAEIMBUMEKWPsVYLAXaABwAHgAgAE9iAGjBZIBAjEzmAEAoAEBqgEHZ3dzLXdpeg&sclient=psy-ab&ved=0ahUKEwj0z_2b1pLpAhWQkxQKHc9cBFEQ4dUDCAk&uact=5#lrd=0x48774bec72256bbb:0x4e7db091bd5b7e76,1,,,'

    return(
      <div className="screen-width-container reviews padding-bottom--max">
        <div className="page-width-container">
          <div className="text-center padding-bottom--base home-heading home-heading--home">
            <h2>Testimonials</h2>
          </div>
          <Row className="justify-content-center reviews-summary padding-bottom--base">
            {this.renderPlatform('Google.co.uk', '5/5', 40, googleSwiftLearner)}
            {this.renderPlatform('Yell.com', '5/5', 100, yellSwiftLearner)}
            {this.renderPlatform('FreeIndex.co.uk', '5/5', 140, freeIndexSwiftLearner)}
          </Row>
          <hr/>
        </div>
        <Carousel interval={10000}>
          <Carousel.Item>
            <div className="page-width-container reviews-carousel">
              <Row className="justify-content-center">
                {this.renderReview('Tamara', 'Thank you so much Mo!! Passed first time with him after doing my driving test 2 times before with somebody else. Mo is always on time and made me aware of certain habits that needed correcting. This had a positive effect on my ability to drive. He is cool and calm, which put me at ease. He is also very easy to get along with, which made me not dread driving lessons anymore.', '19 Mar 2020', 'Yell')}
                {this.renderReview('Andrew', "I was not new to driving but new to driving in UK. He was quick to spot out all mistakes I was used to and he helped correct them in a short amount of time. His experience came in handy for me to make sure I'm aligned to the expectations of the practical exam and helped me clear it first time.", "A year ago", "Google")}
                {this.renderReview('Ugo', "I read great reviews about Mohammed, decided to try him out and discovered that all the reviews were true. He is a great instructor and very friendly, very patient and supportive when you make errors and he knows his stuff. With is help I passed first time with just 4 minors. No one chooses Mo and gets disappointed, trust me.", "13 Jul 2019", 'FreeIndex')}
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="page-width-container reviews-carousel">
              <Row className="justify-content-center">
                {this.renderReview('Vinodhini', "Mohammed is a fantastic teacher. I am a first time driver and he made me feel relaxed and confident before starting the driving classes and the exam. He is punctual for the classes and his guidance is excellent. He is a highly recommend driving instructor.", "31 May 2019", 'FreeIndex')}
                {this.renderReview('Kyle', 'I highly recommend Mohammed. I passed first time with only 3 minors. He adapted his teaching to suit my needs. He was always on time and never canceled any of my lessons. He is also very easy to get along with and made learning to drive enjoyable for me.', 'A year ago', 'Google')}
                {this.renderReview('Zainab', "So glad to have passed with Swift Learner! Mo was an excellent instructor, and made learning how to drive feel really easy! Thanks again!", "6 Sep 2019", 'Yell')}
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="page-width-container reviews-carousel">
              <Row className="justify-content-center">
                {this.renderReview('Brookie D.', "Mohammed is really professional and highly skilled to teach new drivers especially if you're an anxious driver to start with like myself. I would highly recommend swiftlearner to learn driving. Mohammed is very patient and amotivating instructor. He has got all you look for in a perfect instructor. You honestly get all your moneys worth. He also makes learning driving a good experience!", '2 Feb 2019', 'FreeIndex')}
                {this.renderReview('Harold K.', 'I had 3 instructors and i thought there was no hope, until i met Mohammd. There is a reason he has got 5 stars. Pretty calm collected guy and it helps you take the information better. Best instructor, without a doubt.', '27 Apr 2019', 'Yell' )}
                {this.renderReview('Hualong S.', 'Swift is very kind of instructor. I should meet him earlier so that I can save lots of time and money. He teaches every details of the skills that are necessary for passing the driving test. I really recommend him as your instructor.', 'A year ago', 'Google')}
              </Row>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    )
  }
 }
