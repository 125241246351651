import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';

// import '../../assets/styles/home.scss';

// const HeroAsset = lazy(() => import('./homeHeroAsset'));
import HeroAsset from './homeHeroAsset';
import Sections from './Sections';
import Reviews from './Reviews';
import Courses from './Courses';

export default class Home extends React.Component {

  render() {
    return (
      <Fragment>
        <HeroAsset />
        <Sections />
        <LazyLoad height={800}>
          <Reviews />
        </LazyLoad>
        <Courses />
      </Fragment>
    )
  }
}