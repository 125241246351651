import React, { Suspense, Fragment, lazy } from 'react';
import LazyLoad from 'react-lazyload';
import { Helmet } from "react-helmet";

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Router, Redirect } from "@reach/router"

import ReactGA from 'react-ga';

import Header from './components/Header'
import Home from './components/home/Home';
import Footer from './components/Footer';
import ContactUs from './components/contacts/contactUs';
import CookiePopup from './components/cookieBanner';
import TheoryTest from './components/drive/theoryTest';
import Terms from './components/Terms';
import SitewideMessage from './components/sitewide/Message';

const StartDrive = lazy(() => import('./components/drive/startDrive'))
const ManualDrive = lazy(() => import('./components/drive/manualDrive'));
const AutoDrive = lazy(() => import('./components/drive/autoDrive'));
const FemaleInstructor = lazy(() => import('./components/drive/femaleInstructors'));
const PracticalDrivingTest = lazy(() => import('./components/drive/practicalExam'));
const RefresherLessons = lazy(() => import('./components/drive/refresherLessons'));
const PassPlus = lazy(() => import('./components/drive/passPlus'));
const MotorwayLessons = lazy(() => import('./components/drive/motorwayLessons'));
const Faqs = lazy(() => import('./components/contacts/Faqs'));
const IntensiveDriving = lazy(() => import('./components/intensive_driving/intensiveDriving'));
const JoinFranchise = lazy(() => import('./components/join_franchise/joinFranchise'));
const BecomeInstructor = lazy(() => import('./components/become_instructor/becomeInstructor'));
const AboutUs = lazy(() => import('./components/team/About'));
const Covid = lazy(() => import('./components/sitewide/Covid'));

const GACONFIG = {
  trackingId: 'UA-172969840-1',
  gaOptions: {
    cookieFlags: 'domain=swiftlearner.co.uk;path=/;secure;samesite=none'
  }
}

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      visible: false
    };

    this.seenCookie = this.seenCookie.bind(this);
    this.getCookiePopupState = this.getCookiePopupState.bind(this);
    this.maybeShowPopup = this.maybeShowPopup.bind(this);
  }

  async seenCookie() {
    let reqOpts = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        set_cookie_popup: true,
        seen_cookie_popup: true
      })
    }

    try {
      let response = await fetch('/set-cookie-popup', reqOpts);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        let res = response.json();

        this.setState({
          seenCookiePopup: res.set_cookie_popup
        })
      }
    } catch (error) { this.setState({ isLoaded: true, error }) }
  }

  async getCookiePopupState() {
    try {
      let response = await fetch('/cookie-popup');

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        let res = await response.json();
        this.setState({
          isLoaded: true,
          acceptedCookiePopup: res.acpt_cookie_popup,
          seenCookiePopup: res.seen_cookie_popup
        })
      }
    } catch (error) { this.setState({ isLoaded: true, error }) }
  }

  maybeShowPopup() {
    if (!this.state.seenCookiePopup && this.state.isLoaded) {
      this.setState({visible: true});
    }
  }

  renderPopup() {
    if (this.state.visible) {
      return (
        <CookiePopup acptCookie={this.seenCookie} />
      )
    }
    return null;
  }

  componentDidMount() {
    let { trackingId, ...config } = GACONFIG;

    ReactGA.initialize(trackingId, config);
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (!this.state.seenCookiePopup) {
      this.getCookiePopupState();
    }

    setTimeout(() => { this.maybeShowPopup(); }, 3000);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Swift Learner Driving School - Coventry's highest rated driving school</title>
          <meta name="description" content="Learn to drive highest rated driving school in the Coventry area. Swift Learner Driving School provides large variety of excellent quality courses. Start learning now!" />
          <link rel="canonical" href="https://www.swiftlearner.co.uk"/>
        </Helmet>
        <div className="container-fluid">
          <div className="page-width-container header-wrapper">
            <Header />
          </div>
          <div className="screen-width-container padding-top--half padding-bottom--half navbar-custom_wrapper">
            <Navbar expand="lg" className="page-width-container shadow-box shadow-box--top navbar-custom">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto equaly-spaced-elements">
                    <span className="nav-link-wrapper">
                      <NavDropdown id="nav-dropdown-link" className="hover hover-outwards" title="Learn to Drive">
                        <NavDropdown.Item href="/how-to-start-driving">
                          How to Start Driving
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/manual-driving-lessons">
                          Manual Driving Lessons
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/automatic-driving-lessons">
                          Automatic Driving Lessons
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/female-driving-instructors">
                          Female Instructors
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/theory-test">
                          Theory Test
                        </NavDropdown.Item>
                      <NavDropdown.Item href="/practical-driving-test">
                          Practical Driving Test
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/refresher-lessons">
                          Refresher Lessons
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/pass-plus">
                          Pass Plus
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/motorway-lessons">
                          Motorway Lessons
                        </NavDropdown.Item>
                      </NavDropdown>
                    </span>
                    <span className="nav-link-wrapper"><Nav.Link className="hover hover-outwards" href="/intensive-driving-courses">Intensive Driving Courses</Nav.Link></span>
                    <span className="nav-link-wrapper"><Nav.Link className="hover hover-outwards" href="/become-an-instructor">Become an Instructor</Nav.Link></span>
                    <span className="nav-link-wrapper"><Nav.Link className="hover hover-outwards" href="/join-our-franchise">Join our Franchise</Nav.Link></span>
                    <span className="nav-link-wrapper"><Nav.Link className="hover hover-outwards" href="/why-swift-learner">Why Swift Learner?</Nav.Link></span>
                    <span className="nav-link-wrapper"><Nav.Link className="hover hover-outwards" href="/contact">Contact</Nav.Link></span>
                    <span className="nav-link-wrapper"><Nav.Link className="hover hover-outwards" href="/faq">FAQ</Nav.Link></span>
                  </Nav>
                </Navbar.Collapse>
            </Navbar>
          </div>
          <div className='main-content'>
            <Suspense fallback={<div></div>} > {/* < Spinner animation="border" /> */}
              <Router>
                <Home path="/" />
                <StartDrive path="/how-to-start-driving" />
                <ManualDrive path="/manual-driving-lessons"/>
                <AutoDrive path="/automatic-driving-lessons"/>
                <FemaleInstructor path="/female-driving-instructors"/>
                <TheoryTest path="/theory-test"/>
                <PracticalDrivingTest path="/practical-driving-test"/>
                <RefresherLessons path="/refresher-lessons"/>
                <PassPlus path="/pass-plus"/>
                <MotorwayLessons path="/motorway-lessons"/>
                <Terms path="/terms" />
                <ContactUs path="/contact" />
                <Faqs path="/faq" />
                <IntensiveDriving path='/intensive-driving-courses' />
                <JoinFranchise path='/join-our-franchise' />
                <BecomeInstructor path='/become-an-instructor' />
                <AboutUs path='/why-swift-learner' />
                <Covid path='/covid-19' />
                <Redirect from="/*" to='/' default noThrow />
              </Router>
            </Suspense>
          </div>
          <div className="screen-width-container footer-wrapper">
            <LazyLoad height={260} once>
              <Footer />
            </LazyLoad>
          </div>
        </div>
        {this.renderPopup()}
      </Fragment>
    );
  }
}
