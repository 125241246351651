import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const Terms = (() => {
  let page_title = 'Terms & Conditions';
  let desc = "Swift Learner's terms of use and conditions";

  return (
    <Fragment>
      <Helmet>
        <title>Swift Learner Driving School - {page_title}</title>
        <meta name="description" content={desc} />
        <link rel="canonical" href="https://www.swiftlearner.co.uk/terms"/>
      </Helmet>
      <div className="page-width-container padding-bottom--tripple padding-top--base home-heading">
        <div className="narrow_content">
          <div className="padding-top--double padding-bottom--tripple">
            <h1>{page_title}</h1>
          </div>
          <p>The following terms and conditions represent the basis upon which training is offered by Swift Learner. The provision of training is subject to the acceptance of these terms and conditions.</p>
          <ol>
            <li className="padding-bottom--base">
              <strong>Driving licenses and fitness to drive</strong><br/>
              You must hold a current, valid driving license, provisional, full or international, and produce it on (or in advance of) your first training session. You must be fit to drive with regard to legal and medical requirements.
            </li>
            <li className="padding-bottom--base">
              <strong>Payments and Cancellation</strong><br/>
              Tuition fees are normally payable in advance. Payment can be made by cash or bank transfer.
              If either you or your instructor needs to cancel or re-arrange a lesson at least 24 hours notice will be required for single lessons of up to two hours duration. Longer lessons need a minimum of four days notice. Failure to give notice will result in a valid claim for an equivalent value in compensation (i.e., a cancellation fee will be charged or credited)
              Your instructor will do everything possible to ensure that your lessons start and finish on time, but reserves the right to cancel, postpone or change lesson lengths and start/finish times under certain circumstances (e.g., dangerous weather conditions). In the event of postponement fees paid in advance will be carried forward.
              The instructor reserves the right to cancel a lesson at short notice if it is suspected that the pupil may be unfit due to the effects of alcohol, drugs (prescribed or otherwise) or any other condition that would cause his/her driving to be dangerous or illegal. In such circumstances the lesson fee will be payable.
            </li>
            <li className="padding-bottom--base">
              <strong>Bookings and Lesson duration</strong><br />
              Your instructor will endeavor to maintain regular lesson slots at the same time each week to ensure continuity of learning, however, this cannot be guaranteed.
              The minimum lesson period is one hour. If, for any reason, the instructor is late for the lesson he/she will make a concerted effort to inform the pupil of the estimated time of arrival. If the delay is greater than 15 minutes this time will be credited to the pupil and when possible the lesson will be extended by the time due – if this is not possible the extra time will be carried forward to the next suitable lesson.
            </li>
            <li className="padding-bottom--base">
              <strong>Training Location</strong><br />
              All sessions will start and finish at the same location unless alternative arrangements are made in advance. The instructor will determine a location for practical lessons which ensures both the pupil’s and public safety – this means that the instructor may need to drive the pupil to and from the lesson location; this journey time forms part of the lesson as paid for.
            </li>
            <li className="padding-bottom--base">
              <strong>Training vehicles</strong><br />
              Training vehicles provided by the school are taxed, insured for the purposes of driving tuition, fully roadworthy and fitted with dual controls.
              Pupils who require tuition in their own vehicle must supply evidence from their motor insurer that the car is covered for lessons when being supervised by a professional instructor in return for payment; the car must also be taxed and hold a current MOT certificate where appropriate.
            </li>
            <li className="padding-bottom--base">
              <strong>Driving tests and bookings</strong><br />
              Your instructor reserves the right to refuse use of a driving school vehicle for test if he/she considers that provision of a vehicle could cause a risk to public safety.
              Where a school car is used for test, the booking period will based upon the instructor's normal diary schedule. Depending on the time of the test this will require a minimum two hour booking and possibly longer.
              While your instructor will make every effort to ensure that the vehicle supplied for test will be fully road worthy and comply with all legal requirements at the start of the test, he/she cannot be held responsible for vehicle failure that occurs during the test and is not liable for consequential loss.
              Your instructor cannot be held responsible for test appointments cancelled by the DSA due to bad weather, sickness, staff shortages or other reasons. Such cancellations are beyond the control of your instructor and therefore the lesson fee and 'use of car' fee for the booked period will be charged. Your instructor will advise about claiming compensation from the DSA .
            </li>
            <li className="padding-bottom--base">
              <strong>Code of conduct</strong><br />
              Your instructor agrees to abide by the conditions of the Professional Code of Conduct (a copy of which will be provided on request). In the unlikely event of complaint or dispute the guidelines of the Code of Conduct will be adhered to.
            </li>
            <li className="padding-bottom--base">
              <strong>Your rights</strong><br />
              These conditions do not affect any protection a student has under consumer legislation.
              We reserve the right to change or alter any of the terms and conditions without notice, but will endeavour to inform pupils of any changes as soon as possible.
            </li>
            <li className="padding-bottom--base" id="cookie-policy">
              <strong>Cookie Policy</strong><br />
              Analytic Cookies: Our website uses Google Analytic cookies to help us understand how you use the website. The cookies collect information that cannot
              directly identify anyone, such as the number of visitors to the website, where the visitors orignated from, for example Google search or a social media platform.
              We use this information to help us improve the user experience of the website.
              <br />
              We do not use any other third-party cookies that collect any kind of personal or browsing information.
            </li>
          </ol>
        </div>
      </div>
    </Fragment>
  )
})

export default Terms;
