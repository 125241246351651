import React from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import examine from '../../assets/content/images/examiner-small.jpg';
import instructor from '../../assets/content/images/instructor-training-small.jpg';
import female from '../../assets/content/images/female-instructor-small.jpg';

export default class Sections extends React.Component {

  renderCol(title, desc, url, image, styles='') {
    let col_styles = 'section-col section-col--fit-large no-padding--left no-padding--right alight-self-center margin-top--base margin-bottom--base'

    if (styles) { col_styles += (' ' + styles)}

    return(
      <Col md={5} sm={10} xs={10} className={col_styles}>
        <a href={url}>
          <LazyLoad height={258}>
            <Image src={image} alt={title} fluid className="padding-bottom--base section-image-round--top" />
          </LazyLoad>
          <div className="narrow_content section-div">
            <h5 className="padding-top--half padding-bottom--base section-title">{title}</h5>
            <p className="section-p_content">{desc}</p>
            <Button variant="info" className="section-btn margin-top--base margin-bottom--base section-btn" alt={title}>{title}</Button>
          </div>
        </a>
      </Col>
    )
  }

  render() {
    return (
      <div className="screen-width-container home-heading">
        <div className="page-width-container padding-bottom--tripple padding-top--tripple border_line--top">
          <Row lg={3} md={5} className="section justify-content-center text-center">
            {this.renderCol(
              'Learn to Drive',
              "You're looking to learn to drive? Make certain that you choose the right driving school for you, with a great team of driving instructors, a driving school that gives its pupils excellent customer service. Look no further, Swift Learner is the driving school for you.",
              '/how-to-start-driving',
              female,
              'no-margin--left_large')}
            {this.renderCol(
              'Intensive Driving Courses',
              "Would you like to pass your driving test quickly? We are in the process of developing a driving course to help you pass quickly and help make you a confident and safe driver. Keep an eye out for our new courses, which will be available very soon.",
              '/intensive-driving-courses',
              examine)}
            {this.renderCol(
              'Become an Instructor',
              "If you are looking for a new career, or if you want to be your own boss, then train to become a driving instructor with Swift Leaner. You will have the opportunity to earn up to £30,000 in the year or even more. You can work flexible hours to suit your lifestyle and you will have a rewarding career with a secure future.",
              '/become-an-instructor',
              instructor,
              'no-margin--right_large')}
          </Row>
        </div>
      </div>
    )
  }
}
