import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-bootstrap';
import { Location } from '@reach/router';

import ContactForm from './Form';

const ContactUs = (() => {
  let page_title = 'Contact Us'
  let desc = 'Contact us for more information about our services, availability, working hours and bookings. Start learning now!';
  let canonicalUrl = 'https://www.swiftlearner.co.uk/contact';

  return(
    <Location>
      {({location}) =>(
        <Fragment>
          <Helmet>
            <title>{page_title} | Swift Learner Driving School</title>
            <meta name="description" content={desc} />
            <link rel="canonical" href={canonicalUrl}/>
          </Helmet>
          <div className="page-width-container padding-bottom--tripple padding-top--base home-heading">
            <div className="narrow_content">
              <div className="home-heading padding-bottom--tripple padding-top--double">
                <h1>Contact Us</h1>
              </div>
              <Row className="border_line--bottom padding-bottom--tripple margin-bottom--tripple">
                <Col className="center-items">
                  <p className="narrow_content text-center">
                    If you require our services, would like to book a lesson or if you have any other queries, please feel free to contact us using the form below or by sending us an email to: <a className="red" href="mailto:info@swiftlearner.co.uk">info@swiftlearner.co.uk</a>
                  </p>
                </Col>
                <Col>
                  <h4>Working hours:</h4>
                  <div className="center-content contact-us_desc">
                    <dl>
                      <dt>Monday - Saturday:</dt><dd>9:00 - 18:00</dd>
                      <dt>Sunday:</dt><dd>Closed</dd>
                    </dl>
                  </div>
                </Col>
              </Row>
              <ContactForm subject={location.search}/>
            </div>
          </div>
        </Fragment>
      )}
    </Location>
  )
})

export default ContactUs;
