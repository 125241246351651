import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

class CookiePopup extends React.Component {
  constructor() {
    super();

    this.state = { visiblePopup: true};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.acptCookie();
    this.setState({ visiblePopup: false });
  }

  render() {
    if (this.state.visiblePopup) {
      return (
        <div className="screen-width-container padding-top--base padding-bottom--base cookie">
          <div className="page-width-container">
            <Row className="cookie-row">
              <Col md xs={12}>
                <p className="cookie-p">
                  We use cookies to help us analyse traffic data and enhance your experience.
                  By continuing to browse the site you are agreeing to our use of cookies.
                  Read our&nbsp;<a className="cookie_policy-link" href="terms#cookie-policy"><strong>Cookie Policy</strong></a> for more information.
                </p>
              </Col>
              <Col md={3} xs={12} className="center-items">
                <div className="cookie-btn_wrapper">
                  <Button
                    variant="primary"
                    className="cookie-btn--acpt"
                    alt="Accept cookies"
                    onClick={() => this.handleClick()}
                  >
                    Dismiss
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

CookiePopup.propTypes = {
  acptCookie: PropTypes.func.isRequired
}

export default CookiePopup;
